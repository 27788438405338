.ScreenD {
  background-image: url('./images/deep_space_copy.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 5px;
  text-align: center;
  font-family: 'CustomFont', sans-serif;
}

.ScreenD-logo {
  height: 40vmin;
  pointer-events: none;
}

@import './fonts.css';

@media (prefers-reduced-motion: no-preference) {
  .ScreenD-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ScreenD-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  font-family: 'CustomFont', sans-serif;
}

.user-info-container {
  display: flex;
  flex-direction: column;
}

.ScreenD-link {
  color: #61dafb;
}

.button-select {
	background-color: #F2BD27;
	color: white;
	width: 80px;
	height: 30px;
	font-size: 12px;
	font-family: 'CustomFont', sans-serif;
}

.button-shoot {
	background-color: #F2BD27;
	color: white;
	width: 200px;
	height: 80px;
	font-size: 35px;
	font-family: 'CustomFont', sans-serif;
}

.map-container_map {
  width: 100%;
  height: 350px;
  outline-style: dotted;
}

.hidden {
  visibility: hidden;
  font-family: 'CustomFont', sans-serif;
}

.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: #F2BD27;
  height: 50px;
  color: white;
  font-family: 'CustomFont', sans-serif;
}

.button2 {
  background-color: #F2BD27;
  color: white;
  width: 100px;
  height: 80px;
  font-size: 25px;
  font-family: 'CustomFont', sans-serif;
}

.button3 {
  height: 25px;
  background-color: #F2BD27;
  color: white;
  font-family: 'CustomFont', sans-serif;
}


.button-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.button4 {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}



.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
}



.health-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
}

.label {
	color: black;
	height: 13px;
	font-size: 12px;
	font-family: 'CustomFont', sans-serif;
}

.label1 {
	color: white;
	height: 13px;
	font-size: 12px;
	font-family: 'CustomFont', sans-serif;
}

.box {
  height: 20px;
  width: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
  border: 1px solid black;
  clear: both;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.weapon-thumbnail {
  height: 40px;
  border-radius: 10px;
}

/* HIDE RADIO */
.radio-hidden { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type=radio] + img {
  cursor: pointer;
}

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 2px solid #f00;
}

.PlaceStuff {
  height: 100vh;
  width: 100vw;
}

.map-container {
  height: 50%;
  width: 100%;
}