.App {
  background-image: url('./deep_space_copy.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-family: 'CustomFont', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import './fonts.css';

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'CustomFont', sans-serif;
}

.user-info-container {
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #F2BD27;
}

.button-select {
	background-color: #F2BD27;
	color: white;
	width: 80px;
	height: 35px;
	font-size: 12px;
	font-family: 'CustomFont', sans-serif;
}

.button-shoot {
	background-color: #F2BD27;
	color: white;
	width: 200px;
	height: 100px;
	font-size: 35px;
	font-family: 'CustomFont', sans-serif;
}

.map-container_map {
  width: 100%;
  height: 250px;
  outline-style: dotted;
}

.hidden {
  visibility: hidden;
  font-family: 'CustomFont', sans-serif;
}

.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: #F2BD27;
  height: 50px;
  color: white;
  font-family: 'CustomFont', sans-serif;
}

.button2 {
  background-color: #F2BD27;
  color: white;
  width: 100px;
  height: 80px;
  font-size: 25px;
  font-family: 'CustomFont', sans-serif;
}

.button3 {
  height: 25px;
  background-color: #F2BD27;
  color: white;
  font-family: 'CustomFont', sans-serif;
}

.health-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.label {
	color: black;
	height: 14px;
	font-size: 12px;
	font-family: 'CustomFont', sans-serif;
}

.box {
  height: 20px;
  width: 20px;
  margin-bottom: 7px;
  margin-top: 5px;
  border: 1px solid black;
  clear: both;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.weapon-thumbnail {
  height: 40px;
  border-radius: 10px;
}

/* HIDE RADIO */
.radio-hidden { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type=radio] + img {
  cursor: pointer;
}

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 2px solid #f00;
}

.PlaceStuff {
  height: 250px;
  width: 90%;
}

.map-container {
  height: 600px;
  width: 90%;
}